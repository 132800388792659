<template>
    <div class="MemberLevelChange" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form label-width="100px" :model="memberLevel" @submit.native.prevent size="small">
                <el-form-item label="姓名  ">
                    <span>{{ memberLevel.name }}</span>
                </el-form-item>
                <el-form-item label="手机号  ">
                    <span>{{ memberLevel.mobile }}</span>
                </el-form-item>
                <el-form-item label="性别  ">
                    <span>{{ memberLevel.sex | sex }}</span>
                </el-form-item>
                <el-form-item label="机构组名称">
                    <div v-if="meta.deptGroups.length > 1">
                        <el-select
                            v-model="memberLevel.deptGroupCode"
                            @change="handleChangeGroup"
                            filterable
                            :loading="loadingDeptGroupFlag"
                        >
                            <el-option
                                v-for="group in meta.deptGroups"
                                :value="group.code"
                                :key="group.code"
                                :label="group.name"
                            />
                        </el-select>
                    </div>
                    <div v-if="meta.deptGroups.length <= 1">
                        <span>{{ memberLevel.deptGroupName }}</span>
                    </div>
                </el-form-item>
                <el-form-item label="现有等级">
                    <span>{{ memberLevel.nowLevel }}</span>
                </el-form-item>
                <el-form-item label="变更等级">
                    <el-select placeholder="等级" v-model="memberLevel.newLevel">
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="level in meta.memberLevels"
                            :value="level.level"
                            :key="level.level"
                            :label="level.name + '(' + level.discount + '折)'"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSave">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import Utils from '../../../../js/Util';

export default {
    name: 'MemberLevelChange',
    props: ['form'],
    data() {
        return {
            memberLevel: {
                memberCode: this.form.code,
                name: this.form.name,
                mobile: this.form.mobile,
                sex: this.form.sex,
                deptGroupCode: '',
                deptGroupName: '',
                newLevel: '',
                nowLevel: '',
            },
            meta: {
                deptGroups: [],
                memberLevels: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/list',
                queryMemberLevel: '/member/level/queryMemberLevel',
                changeLevel: '/member/changeMemberLevel',
            },
        };
    },
    filters: {
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            }
            if (sex == '1') {
                return '女';
            }
        },
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            rst = rst || [];
            this.loadingDeptGroupFlag = false;
            if (rst.length > 0) {
                this.meta.deptGroups = rst;
                this.memberLevel.deptGroupName = rst[0].name;
                this.memberLevel.deptGroupCode = rst[0].code;
                this.handleChangeGroup(this.memberLevel.deptGroupCode);
            }
        });
    },
    methods: {
        handleChangeGroup(value) {
            const _this = this;
            const _params = { params: { deptGroup: value, memberCode: _this.memberLevel.memberCode } };
            Utils.queryTable(_this, _this.url.queryMemberLevel, _params, (data) => {
                _this.meta.memberLevels = data.data.memberLevels;
                _this.memberLevel.newLevel = data.data.nowLevel;
                _this.memberLevel.nowLevel = data.data.nowLevel || '未设置';
            });
        },
        handleSave() {
            const _this = this;
            if (!_this.memberLevel.deptGroupCode) {
                this.$message.error('请选择机构组');
                return;
            }
            if (!_this.memberLevel.newLevel) {
                this.$message.error('没有设置变更等级');
                return;
            }
            const _params = {
                memberCode: _this.memberLevel.memberCode,
                deptGroupCode: _this.memberLevel.deptGroupCode,
                level: _this.memberLevel.newLevel,
            };
            UrlUtils.PatchRemote(_this, _this.url.changeLevel, _params, null, (data) => {
                _this.goBackAndReload();
                this.$message.success('变更成功');
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
